import React from 'react'
import "./Home.scss"
import NewHeader from './Header/NewHeader'
import { Button, TextField,Alert } from '@mui/material'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import {useForm} from 'react-hook-form'
import { QRCode } from 'react-qrcode-logo';
import Lottie from 'react-lottie';
import video from './Utils/video.mp4'
import CircularProgress from '@mui/material/CircularProgress';

//import Fireworks from './fireworks.json'
function Home(props) {
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: Fireworks,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice"
  //   }
  // };
    const {handleSubmit,register,formState:{errors}}=useForm()
    const [error,setError] = React.useState(null)
    const [amount,setAmount] = React.useState(null)
    const [loading,setLoading]=React.useState(false)
    const onSubmit = (data)=>{
        setLoading(true)
        axios.post(`${process.env.REACT_APP_PRE}/preapproval`,{...data})
        .then(res=>{
            setLoading(false)
            setError(null)
            if(res.data.msg==="Success"){   
                setAmount(res.data.amount)
            }
        })
        .catch(err=>{
            setLoading(false)
            setAmount(null)
            if(err.response){
                if(err.response.data){
                    setError(err.response.data)
                }
                
            }
        })
    }
    console.log(JSON.parse(process.env.REACT_APP_NATIVE_FLAG))
  return (
    <div className='home'>
    {/* navbar */}
    <NewHeader id={1} />
   {/* navbar */}
   <div className="div-1">
   {/* <Lottie 
    style={{position:"absolute"}}
          options={defaultOptions}
          height={1000}
        /> */}
    {/* <img src="/lights2.png" alt="lights" className="lights" />
    */}
    {/* <img src="/lights2.png" alt="lights" className="lights" />  */}

    <h1 className="home-heading">The Smartest way India Pay</h1>
    <p>Simplifying payment methods through an instant, card-less & hassle free affordability solutions such as Buy Now Pay Later & No Cost EMIs.</p>
    </div>


    {/* <img className="money-img" src="/tvt.gif" alt="money" /> */}

    <div className="video-div">
    <video className="video-style" loop muted autoPlay  >
      <source src={video} type="video/mp4"/>
     </video>
     </div>

    <section className='card-section'>
    {/* <h1 className="home-heading">No card? No worries!</h1> */}
    <h1 className="home-heading mb-5">Check your credit limit within seconds</h1>

    <div className="cred-check-div">
    <form onSubmit={handleSubmit(onSubmit)}>
        <TextField 
          InputLabelProps={{
            style: {
              color: 'white', // Label color
            },
          }}
          InputProps={{
            style: {
              color: 'white', // Text color for the entered text
              backgroundColor: 'transparent', // Make the background transparent
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 1000px transparent inset', // Override autofill background color
              },
            },
          }}
          sx={{
            color: 'white', // Text color
            '& .MuiOutlinedInput-root .MuiInputBase-root': {
              color: 'white', // Text color
            },
            '& .MuiFormLabel-root': {
              color: 'white', // Label color
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Outline color
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Outline color when focused
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Outline color on hover
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              borderColor: 'white', // Textfield border color on focus
            },
          }}
        error={errors.customerName?true:false} {...register("customerName",{required:true})} className="my-3" fullWidth variant="outlined" id="outlined-basic" label="Name as per Pan Card" />
        <TextField 
          InputLabelProps={{
            style: {
              color: 'white', // Label color
            },
          }}
          InputProps={{
            style: {
              color: 'white', // Text color for the entered text
            },
          }}
          sx={{
            color: 'white', // Text color
            '& .MuiOutlinedInput-root .MuiInputBase-root': {
              color: 'white', // Text color
            },
            '& .MuiFormLabel-root': {
              color: 'white', // Label color
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Outline color
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Outline color when focused
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Outline color on hover
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              borderColor: 'white', // Textfield border color on focus
            },
          }}
        error={errors.mobileNo?true:false} {...register("mobileNo",{required:true,valueAsNumber:true})} className="my-3" fullWidth variant="outlined" id="outlined-basic" label="Mobile Number" />
        {error&&<Alert className="alert" severity="error">{error}</Alert>}
        {amount&&<Alert className="alert" severity="success">Congratulations! you have got a pre-approved credit limit of ₹<b>{amount}</b></Alert>}
        {loading?
        <div style={{textAlign:"center"}} className='mt-4'>
        <CircularProgress color="secondary" />
        </div>
        // <LoadingButton fullWidth loading variant="contained">
        // Submit
        // </LoadingButton>
        :
        <Button className="mt-4" color="secondary" 
        // endIcon={<PlagiarismIcon />} 
        type="submit" sx={{paddingTop:1.3,paddingBottom:1.3}} variant="contained" fullWidth>Check Now</Button>
        }
        
    </form>
    </div>
    </section>


    <h2 className="home-heading" >Already Placed an order on CleverPe?</h2>
    <div className="button" style={{textAlign:"center"}}>
        <button onClick={()=>props.history.push("/usersignin")} className="white-button">Click to Log In</button>
      </div>

    {/* <h1 className="mt-5 home-heading">Coming Soon For The Users!</h1> */}

    <div className="div-3">
        <div className="row m-auto justify-content-between align-items-center">
            <div>
                <h2 className="home-heading">Be Clever-Pe Later</h2>
            </div>
            <div>
                <p className="my-3">Smart and hassle free process</p>
            </div>
        </div>

        <div className="row m-auto justify-content-between align-items-center info-cards">
            <div className="card-home col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style={{textAlign:"center"}}>
                <p>Instant Credit up to 20k</p>
            </div>
            <div className="card-home col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style={{textAlign:"center"}}>
                <p>No Cost & Low Cost EMI</p>
            </div>
            <div className="card-home col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style={{textAlign:"center"}}>
                <p>100% Digital Journey</p>
            </div>
            <div className="card-home col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style={{textAlign:"center"}}>
                <p>Cashbacks & more</p>
            </div>
            <div className="card-home col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style={{textAlign:"center"}}>
                <p>Brand EMI Available</p>
            </div>
            <div className="card-home col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style={{textAlign:"center"}}>
                <p>Pay Later in 30 Days</p>
            </div>
        </div>
    </div>


    <div className="div-4">
        <h3>Join Our Merchant Community</h3>
        <p>Get exciting offers and cashbacks on various products by transacting with cleverpe</p>
        <div className="button">
        <button 
        onClick={()=>props.history.push("/merchantsignup")} 
        className="white-button">Join CleverPe</button>
      </div>
    </div>

    </div>
  )
}

export default Home